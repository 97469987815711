<div class="terms-block">
  <div class="accordion">
    <div class="accordion__header"
         Accordion
         #accordionHeader=accordionDirective
         #termsAcc
         (click)='onClickHeader()'>
      <span class="accordion__header-label" [ngClass]="{'text-gradient': showGradientHeader}">
        {{ accordionTitle | translate }}
      </span>
      <i class="accordion__header-icon icon-arrow"></i>
    </div>
    <div class="accordion__content">
      <div class="accordion__content-container" [innerHTML]="terms | safe:'html'"></div>
    </div>
  </div>
</div>
