
@switch (user.auth) {
  @case (true) {
    <div class="card">
      <ng-container *ngTemplateOutlet="authed"></ng-container>
    </div>
  }
  @case (false) {
    <div class="card">
      <ng-container *ngTemplateOutlet="notAuthed"></ng-container>
    </div>
  }
}

<ng-template #notAuthed>
  <div class="tournament-preview">
    <div class="tournament-preview__info">
      <h2 class="tournament-preview__info--title">
        {{tournamentItem?.Title}}
      </h2>
      <div class="tournament-preview__info--prize">
        <div class="dark-title">{{'t.prize-pool' | translate}}:</div>
        <div class="prize-content"
          fittext
          [minFontSize]="lang.current === 'fi' ? 18: 23"
          [maxFontSize]="30">
          <div [innerHTML]="tournamentItem?.Prize | safe:'html'"></div>
        </div>
        <app-tournament-preview-timer
          [status]="tournamentItem?.tournamentTimeStatus"
          [timeLeft]="tournamentItem?.endAt">
        </app-tournament-preview-timer>
      </div>
      <div class="tournament-preview__info--action">
        <button
          class="btn small"
          fittext [maxFontSize]="12"
          (click)="onAuthAction()">{{user?.authKeyTranslate | translate}}
        </button>
      </div>
    </div>
    <div class="tournament-preview__image">
      <img [src]="tournamentItem?.PreviewImage" alt="">
    </div>
    <div class="tournament-row">
      <app-game-list
        [ngClass]="{hide: tournamentItem?.tournamentTimeStatus === Status.FUTURE}"
        [config]="tournamentGamesConfig">
      </app-game-list>
    </div>
  </div>
</ng-template>

<ng-template #authed>
  <div class="tournament-preview">

    <div class="tournament-preview__info">
      <h2 class="tournament-preview__info--title">
        {{tournamentItem?.Title}}
      </h2>
      <div class="tournament-preview__info--prize">
        <div class="dark-title">{{'t.prize-pool' | translate}}:</div>
        <div class="prize-content"
          fittext
          [minFontSize]="lang.current === 'fi' ? 18: 23"
          [maxFontSize]="30">
          <div [innerHTML]="tournamentItem?.Prize | safe:'html'"></div>
        </div>
        <app-tournament-preview-timer
          [status]="tournamentItem?.tournamentTimeStatus"
          [timeLeft]="tournamentItem?.endAt">
        </app-tournament-preview-timer>
        @if (tournamentItem?.tournamentTimeStatus !== Status.FUTURE) {
          @if (tournamentItem?.tournamentUserStatus$ | async; as status) {
            @if (status?.award_place) {
              <div class="place text-gradient">
                {{'t.your-place' | translate}} #{{status?.award_place}}
              </div>
            }
          }
        }
      </div>
      <div class="tournament-preview__info--action">
        <button
          class="btn small"
          [disabled]="tournamentItem?.tournamentTimeStatus === Status.FUTURE"
          [routerLink]="['/', 'tournaments', tournamentItem?.slug]">
          {{'btn.more' | translate}}
          <i class="icon-arrow"></i>
        </button>
      </div>
    </div>

    <div class="tournament-preview__image">
      <img [src]="tournamentItem?.PreviewImage" alt="">
    </div>

    <div class="tournament-row">
      @if (tournamentItem?.tournamentTimeStatus === Status.NOW || tournamentItem?.tournamentTimeStatus === Status.FUTURE) {
        <app-game-list
          [ngClass]="{hide: tournamentItem?.tournamentTimeStatus === Status.FUTURE}"
          [config]="tournamentGamesConfig">
        </app-game-list>
      }

      @if (tournamentItem?.tournamentTimeStatus === Status.PAST) {
        <div class="tournament-preview__past">
          @if (tournamentItem?.prizesData$ | async) {
            <app-tournament-prizes [tournament]="tournamentItem"></app-tournament-prizes>
            <app-tournament-leaders [tournament]="tournamentItem"></app-tournament-leaders>
          } @else {
            <app-game-list
              [config]="tournamentGamesConfig">
            </app-game-list>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
