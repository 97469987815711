import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { PaymentMode, UserPaymentModeService } from '../../../services/user/user-payment-mode.service';
import { UserAuthService } from '../../../services/user/user-auth.service';
import { CommonDataService } from '../../../services/common-data.service';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NgModel, ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TranslatePipe } from '../../../services/translation/translate.pipe';
import { SelectOptionComponent } from '../../modules/select/select-option/select-option.component';
import { SelectDropdownComponent } from '../../modules/select/select-dropdown/select-dropdown.component';
import { SelectHeaderComponent } from '../../modules/select/select-header/select-header.component';
import { SelectComponent } from '../../modules/select/select.component';
import { FormInputComponent } from '../form-controls/form-input/form-input.component';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'app-currency-payment-mode-selector',
    templateUrl: './currency-payment-mode-selector.component.html',
    styleUrls: ['./currency-payment-mode-selector.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => CurrencyPaymentModeSelectorComponent),
            multi: true
        }
    ],
    standalone: true,
    imports: [NgTemplateOutlet, FormInputComponent, SelectComponent, ReactiveFormsModule, FormsModule, SelectHeaderComponent, SelectDropdownComponent, SelectOptionComponent, TranslatePipe]
})
export class CurrencyPaymentModeSelectorComponent implements OnInit, ControlValueAccessor {

  /**
   * Select model
   */
  @ViewChild('selectModel') selectModel: NgModel;

  private _value: any;

  private _onChanged = (_: any) => {
  }
  private _onTouched = (_: any) => {
  }

  constructor(
    public paymentMode: UserPaymentModeService,
    public auth: UserAuthService,
    private _commonData: CommonDataService
  ) {
  }

  get currencyListByPaymentMode(): any[] {
    if (this.paymentMode.currentMode === PaymentMode.CRYPTO) {
      return this._commonData?.cryptoCurrencyList;
    } else {
      return this._commonData?.fiatCurrencyList;
    }
  }

  set value(val) {
    this._onChanged(val);
    this._onTouched(val);
  }

  get value(): any {
    return this._value;
  }

  get mode() {
    return PaymentMode;
  }

  ngOnInit(): void {
  }

  registerOnChange(fn: any): void {
    this._onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this._onTouched = fn;
  }

  writeValue(val: any): void {
    this._value = val;
  }

  toggleSelector(mode: PaymentMode) {
    this.paymentMode.changePaymentMode(mode);
    this.auth.setDefaultCurrency();
  }

}
