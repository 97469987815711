@if (!paymentMode.isModeCrypto) {
  <div class="selector">
    <div (click)="toggleSelector(mode.CRYPTO)" class="selector__mode">
      {{'t.crypto' | translate}}
    </div>
    <div class="selector__currency">
      <ng-container *ngTemplateOutlet="select"></ng-container>
    </div>
  </div>
}

@if (paymentMode.isModeCrypto) {
  <div class="selector">
    <div class="selector__currency">
      <ng-container *ngTemplateOutlet="select"></ng-container>
    </div>
    <div (click)="toggleSelector(mode.FIAT)" class="selector__mode">
      {{'t.local-currencies' | translate}}
    </div>
  </div>
}

<ng-template #select>
  <app-form-input [control]="auth.registerForm.get('currency')">
    <app-select
      class="select"
      [(ngModel)]="value"
      #selectModel="ngModel"
      >
      <app-select-header class="select__header">{{ value }}</app-select-header>
      <app-select-dropdown class="select__dropdown">
        @for (currency of currencyListByPaymentMode; track currency) {
          <app-select-option
            class="select__option"
            [value]="currency?.code"
            >
            {{ currency?.code }}
          </app-select-option>
        }
      </app-select-dropdown>
    </app-select>
  </app-form-input>
</ng-template>
