import { Component, Input, ViewEncapsulation } from '@angular/core';
import { ILoyaltyStatic, UserLoyaltyService } from '../../../services/user/user-loyalty.service';
import { UserService } from '../../../services/user/user.service';
import { Router } from '@angular/router';
import { TranslatePipe } from '../../../services/translation/translate.pipe';


@Component({
    selector: 'app-unlock-loyalty',
    templateUrl: './unlock-loyalty.component.html',
    styleUrls: ['./unlock-loyalty.component.scss'],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [TranslatePipe],
})
export class UnlockLoyaltyComponent {
  public neededPoints: number;

  @Input() set data(value: any) {
    if (value.isAccount) {
      let closestObject = null;
      let closestDifference = Infinity;

      for (const obj of value?.levels) {
        for (const sublevel of obj.sublevels) {
          const difference = Math.abs(sublevel.pointsFrom - value.pts);
          if (difference <= closestDifference && sublevel.pointsFrom > value.pts) {
            closestDifference = difference;
            closestObject = sublevel;
          }
        }
      }

      if (closestObject) {
        this.neededPoints = closestObject.pointsFrom - value.pts;
      }
    } else {
      this.neededPoints = value?.pointsFrom - value.pts;
      if (this.neededPoints === 0) {
        this.neededPoints = value.nextLevel.pointsFrom - value.pts;
      }
    }
  }

  constructor(
    private _user: UserService,
    public loyalty: UserLoyaltyService
  ) {
  }
}
