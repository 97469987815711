import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NotificationCenterComponent } from './notification-center/notification-center.component';
import { NotificationSidebarComponent } from './notification-sidebar/notification-sidebar.component';
import { NotificationItemComponent } from './notification-item/notification-item.component';

import { RouterModule } from '@angular/router';

import {PipesModule} from '../../pipes/pipes.module';

@NgModule({
    exports: [
        NotificationCenterComponent,
        NotificationSidebarComponent,
        NotificationItemComponent
    ],
    imports: [
    CommonModule,
    RouterModule,
    PipesModule,
    NotificationCenterComponent,
    NotificationSidebarComponent,
    NotificationItemComponent
]
})
export class NotificationCenterModule {
}
