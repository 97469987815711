import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PlatformService } from '../../../services/platform.service';

@Component({
    selector: 'app-loyalty-progress-bar',
    templateUrl: './loyalty-progress-bar.component.html',
    styleUrls: ['./loyalty-progress-bar.component.scss'],
    standalone: true
})
export class LoyaltyProgressBarComponent implements OnInit, AfterViewInit {

  /**
   * Native bar element ref
   * @private
   */
  @ViewChild('bar') private _bar: ElementRef;

  /**
   * Current progress value
   */
  private _current: number;

  /**
   * Interval var
   * @private
   */
  private _interval: any;

  /**
   * Progress counter
   * @private
   */
  private _progress = 0;

  /**
   * Set current progress value
   */
  @Input('current') set current(value: number) {
    this._current = value;
  }

  constructor(
    private _platform: PlatformService
  ) {
  }

  ngOnInit(): void {
  }

  ngAfterViewInit() {
    this._updateProgress();
  }

  /**
   * Update current progress value
   *
   * @private
   */
  private _updateProgress() {
    if (this._platform.isBrowser) {
      this._setInterval();
    }
  }

  private _setInterval() {
    this._interval = setInterval(() => {
      if (this._current === 0) {
        this._progress = 0;
        this._bar?.nativeElement?.setAttribute('style', `width: ${ Math.floor(this._progress) }%`);
        this._resetInterval();
      } else if (this._current) {
        this._progress += 1;
        this._bar?.nativeElement?.setAttribute('style', `width: ${ Math.floor(this._progress) }%`);
      }
      if (this._progress === this._current) {
        this._progress = 0;
        this._resetInterval();
      }
    }, 20);
  }

  private _resetInterval() {
    clearInterval(this._interval);
  }

}
