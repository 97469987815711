import { Component, Input } from '@angular/core';
import { BonusShopPage } from '../../../../page/bonus-shop/bonus-shop.types';


@Component({
    selector: 'app-earn-points',
    templateUrl: './earn-points.component.html',
    styleUrls: ['./earn-points.component.scss'],
    standalone: true,
    imports: [],
})
export class EarnPointsComponent {

  @Input() pageData: BonusShopPage | undefined;

}
