import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { of } from 'rxjs';
import { delay, tap } from 'rxjs/operators';
import { AccordionDirective } from '../../directives/accordion.directive';
import { TranslatePipe } from '../../../services/translation/translate.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { NgClass } from '@angular/common';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'app-bottom-terms',
  templateUrl: './bottom-terms.component.html',
  styleUrls: ['./bottom-terms.component.scss'],
  standalone: true,
  imports: [
    AccordionDirective,
    NgClass,
    SafePipe,
    TranslatePipe,
  ],
})
export class BottomTermsComponent {

  @Input() terms: string;
  @Input() accordionTitle = 'labl.t&c';
  @Input() showGradientHeader: boolean;

  @ViewChild('termsAcc') termsAcc: ElementRef;
  @ViewChild('accordionHeader') accordionHeader: AccordionDirective;

  onClickHeader() {
    this._scrollView();
  }

  /**
   * Auto scroll submenu
   * @param el
   * @private
   */
  private _scrollView() {
    if (this.accordionHeader.isOpen) {
      of(true).pipe(
        untilDestroyed(this),
        delay(100),
        tap(() => {
          this.termsAcc.nativeElement
            .scrollIntoView({ behavior: 'smooth' });
        }),
      ).subscribe();
    }
  }

}
