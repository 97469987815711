import { Component, ElementRef, OnInit, ViewChild, AfterViewInit, OnDestroy, Input, Output, EventEmitter, ChangeDetectionStrategy, HostListener } from '@angular/core';
import { PlatformService } from 'src/app/core/services/platform.service';
import ScrollBooster from 'scrollbooster';
import { AbstractScrollBooster, ScrollBoosterConfig, ScrollingState } from './scroll-booster.interface';
import { Debounce } from '../../decorators/debounce';
import { ScrollService } from '../../../services/scroll.service';

@Component({
    selector: 'app-scroll-booster',
    templateUrl: './scroll-booster.component.html',
    styleUrls: ['./scroll-booster.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
})
export class ScrollBoosterComponent implements OnInit, AfterViewInit, OnDestroy {

  private _scrollbooster: AbstractScrollBooster = null;

  private _defaultConfig: Partial<ScrollBoosterConfig> = {
    direction: 'horizontal',
    scrollMode: 'native',
    emulateScroll: true,
    disableScrollOnHover: true,
  };

  /**
   * Config
   */
  @Input('config') config: Partial<ScrollBoosterConfig> = {};

  @Output() onUpdate: EventEmitter<ScrollingState> = new EventEmitter();
  @Output() onClick: EventEmitter<any> = new EventEmitter();
  @Output() onWheel: EventEmitter<any> = new EventEmitter();
  @Output() onInit: EventEmitter<boolean> = new EventEmitter<boolean>();

  @ViewChild('viewport', {static: true}) viewport: ElementRef<HTMLElement>;
  @ViewChild('content', {static: true}) content: ElementRef<HTMLElement>;

  @HostListener('mouseenter', ['$event'])
  @HostListener('mouseleave', ['$event'])
  @Debounce() onMouseMove(event: Event) {
    if (this.config.disableScrollOnHover) {
      if (event.type === 'mouseleave') {
        this._scroll.UnblockScroll();
      } else {
        this._scroll.blockScroll();
      }
    }
  }

  constructor(
    private _platform: PlatformService,
    private _scroll: ScrollService,
  ) {
  }

  public get scrollbooster(): AbstractScrollBooster {
    return this._scrollbooster;
  }

  ngOnInit() {
    if (this._platform.isBrowser) {
      this.config = {...this._defaultConfig, ...this.config};
    }
  }

  ngAfterViewInit() {
    if (this._platform.isBrowser) {
      this._scrollbooster = new ScrollBooster({
        ...this.config,
        viewport: this.viewport.nativeElement,
        content: this.content.nativeElement,
        onUpdate: (state) => {
          this.onUpdate.next(state);
        },
        onClick: (state, event) => {
          this.onClick.next({state, event});
        },
        onWheel: (state, event) => {
          this.onClick.next({state, event});
        },
      });
      this.onInit.next(true);
    }
  }

  ngOnDestroy() {
    if (this._scrollbooster) {
      this._scrollbooster.destroy();
    }
  }

}
