import {Component, OnInit} from '@angular/core';
import {FullScreenService} from '../../../services/full-screen.service';
import {UserService} from '../../../services/user/user.service';
import {GameIframeService} from '../../../services/games/game-iframe.service';
import {JackpotService, JackpotLevelsPlace} from '../../../../page/jackpot/jackpot.service';
import { LocalstorageService } from 'src/app/core/services/localstorage.service';
import { JackpotLevelsComponent } from '../jackpot-levels/jackpot-levels.component';
import { DraggableDirective } from '../../directives/draggable.directive';
import { NgClass } from '@angular/common';

@Component({
    selector: 'app-game-iframe',
    templateUrl: './game-iframe.component.html',
    styleUrls: ['./game-iframe.component.scss'],
    standalone: true,
    imports: [DraggableDirective, NgClass, JackpotLevelsComponent]
})
export class GameIframeComponent implements OnInit {

  /**
   * Toggle when drag and display div for right dragging working
   */
  public dragging: boolean;

  public levelPlaces = JackpotLevelsPlace;

  constructor(
    public gameIframe: GameIframeService,
    public user: UserService,
    public fullScreen: FullScreenService,
    public jackpot: JackpotService,
    private _localStorage: LocalstorageService,
  ) {
  }

  ngOnInit() {

  }

  public closeIframe() {
    const savedGames = JSON.parse(this._localStorage.get('savedGames')) || [];
    const gameSaved = savedGames.findIndex((game) => game.game.id === this.gameIframe.game.id);

    if (gameSaved !== -1) {
      savedGames[gameSaved] = { game: '', source: '' };
      this._localStorage.set('savedGames', JSON.stringify(savedGames));
    }

    this.gameIframe.closeGame();
  }
}
