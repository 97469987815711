import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ModalComponent } from './modal.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ModalTemplateListImports } from './templates';
import { ModalRegularListImports } from './components/regular';

import { ComponentsModule } from '../shared/components/components.module';


import { PipesModule } from '../shared/pipes/pipes.module';


@NgModule({
    imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    PipesModule,
    ModalComponent,
    ...ModalTemplateListImports,
    ...ModalRegularListImports,
],
    exports: [
        ModalComponent,
    ],
})
export class ModalModule {
}
