import { Component, Input, OnInit, OnChanges, ChangeDetectionStrategy } from '@angular/core';
import { map } from 'rxjs/operators';
import { originalOrder } from 'src/app/core/helpers/utils';
import { ModalService } from 'src/app/core/modal/modal.service';
import { ITournament } from 'src/app/page/tournaments/tournaments.interface';
import { TranslatePipe } from '../../../services/translation/translate.pipe';
import { KeysCountPipe } from '../../pipes/keys-count.pipe';
import { TextGradientComponent } from '../text-gradient/text-gradient.component';
import { NgTemplateOutlet, NgClass, AsyncPipe, SlicePipe, KeyValuePipe } from '@angular/common';

@Component({
  selector: 'app-tournament-prizes',
  templateUrl: './tournament-prizes.component.html',
  styleUrls: ['./tournament-prizes.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    TextGradientComponent,
    AsyncPipe,
    SlicePipe,
    KeyValuePipe,
    KeysCountPipe,
    TranslatePipe
],
})
export class TournamentPrizesComponent implements OnInit, OnChanges {

  public prizesData$: ITournament['prizesData$'] = null;

  public originalOrder = originalOrder;

  @Input('tournament') tournament: ITournament | any = null;
  @Input() alternativeStyle: boolean;
  @Input() hideTitle: boolean;

  constructor(
    private _modal: ModalService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.tournament) {
      this.prizesData$ = this.tournament.prizesData$.pipe(
        map((e: any) => {
          e.previewPrizes = e.mappedPrizes.slice(0, 5);
          e.mappedPrizes = e.mappedPrizes.slice(0, 50);
          return e;
        }),
      );
    }
  }

  async showPrizes() {
    const component = await import('../../../modal/components/lazy/tournament-prize-modal/tournament-prize-modal.component');
    await this._modal.openLazy(component?.TournamentPrizeModalComponent, {
      data: this.tournament,
      template: 'CLEAR',
    });
  }

}
