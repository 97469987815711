<div class="earn-container">
  <div class="earn-container__title">
    {{ pageData?.HowEarnTitle }}
  </div>
  <div class="earn-container__steps">
    @for (step of pageData?.HowEarnSteps; track step; let i = $index) {
      <div class="earn-container__steps--item card">
        <div class="earn-container__steps__number">
          {{ i + 1 }}
        </div>
        <div class="earn-container__steps--item__title" [innerHTML]="step?.Title"></div>
      </div>
    }
  </div>
</div>
