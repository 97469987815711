
@switch (status) {
  @case (timelineStatus?.NOW) {
    <ng-container *ngTemplateOutlet="now"></ng-container>
  }
  @case (timelineStatus?.FUTURE) {
    <ng-container *ngTemplateOutlet="future"></ng-container>
  }
  @case (timelineStatus?.PAST) {
    <ng-container *ngTemplateOutlet="past"></ng-container>
  }
}

<ng-template #now>
  @if (timeLive) {
    <div class="tournament-timer">
      <div class="tournament-timer__section">
        <span class="value">{{timeLive?.days}}</span>
        <span class="label">{{timeLive?.daysLabel | translate}}</span>
      </div>
      <div class="tournament-timer__section">
        <span class="value">{{timeLive?.hours}}</span>
        <span class="label">{{timeLive?.hoursLabel | translate}}</span>
      </div>
      <div class="tournament-timer__section">
        <span class="value">{{timeLive?.minutes}}</span>
        <span class="label">{{timeLive?.minLabel | translate}}</span>
      </div>
    </div>
  }
</ng-template>

<ng-template #future>
  @if (timeLive) {
    <div class="tournament-timer">
      <div class="tournament-timer__status">
        {{'t.will-be-launched-soon' | translate}}
      </div>
    </div>
  }
</ng-template>

<ng-template #past>
  @if (timeLive) {
    <div class="tournament-timer">
      <div class="tournament-timer__status">
        {{'t.finished' | translate}}
      </div>
    </div>
  }
</ng-template>

