@if (neededPoints) {
  <div class="locked-status">
    <div class="locked-status__icon">
      <i class="icon-lock"></i>
    </div>
    <div>
      <div (click)='loyalty.onClickEarn($event)' class="locked-status__content"
        [innerHTML]="'t.unlock-bonus-loyalty' | translate : {count: neededPoints} ">
      </div>
      <div class="locked-status__coin">
        <img src="/assets/svg/icons/exchange.svg" alt="">
        {{'t.point' | translate}}
      </div>
    </div>
  </div>
}
