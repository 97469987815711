import { Component, Input, OnInit, OnChanges } from '@angular/core';
import { TimelineStatus } from 'src/app/core/services/time.service';
import { UserService } from 'src/app/core/services/user/user.service';
import { GameListConfig } from 'src/app/core/shared/components/game-list/game-list.component';
import { ITournament } from 'src/app/page/tournaments/tournaments.interface';
import { TournamentsService } from 'src/app/page/tournaments/tournaments.service';
import { LanguageService } from '../../../services/language.service';
import { LocalHistoryService } from '../../../services/local-history.service';
import { TranslatePipe } from '../../../services/translation/translate.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { TournamentLeadersComponent } from '../tournament-leaders/tournament-leaders.component';
import { TournamentPrizesComponent } from '../tournament-prizes/tournament-prizes.component';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { TextGradientComponent } from '../text-gradient/text-gradient.component';
import { GameListComponent } from '../game-list/game-list.component';
import { BtnComponent } from '../btn/btn.component';
import { TournamentPreviewTimerComponent } from '../tournament-preview-timer/tournament-preview-timer.component';
import { FittextDirective } from '../../directives/fit-text.directive';
import { NgTemplateOutlet, NgClass, AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-tournament-preview',
    templateUrl: './tournament-preview.component.html',
    styleUrls: ['./tournament-preview.component.scss'],
    standalone: true,
    imports: [
    NgTemplateOutlet,
    FittextDirective,
    TournamentPreviewTimerComponent,
    BtnComponent,
    GameListComponent,
    NgClass,
    TextGradientComponent,
    RouterLink,
    RouterLinkDirective,
    TournamentPrizesComponent,
    TournamentLeadersComponent,
    AsyncPipe,
    SafePipe,
    TranslatePipe
],
})
export class TournamentPreviewComponent implements OnInit, OnChanges {

  /**
   * Config for game slide list
   */
  public tournamentGamesConfig: GameListConfig;

  /**
   * Tournament item data
   */
  @Input('tournamentItem') tournamentItem: ITournament = null;

  constructor(
    public tournaments: TournamentsService,
    public user: UserService,
    public lang: LanguageService,
    private _local: LocalHistoryService,
  ) {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {
    if (this.tournamentItem) {
      this.tournamentGamesConfig = {
        title: '',
        link: `${ this.lang.current }/tournaments/${ this.tournamentItem?.slug }/games`,
        type: 'tournament',
        filter: {
          limit: 4,
        },
        gameList: this.tournamentItem?.gameList,
      };
    }
  }

  async onAuthAction() {
    this._local.addUrl('tournaments', '/tournaments');
    await this.user.authUser();
  }

  get Status() {
    return TimelineStatus;
  }

}
