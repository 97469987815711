@if (gameIframe.source) {
  <div
    dragg
    [inViewport]="true"
    [dragOffset]="60"
    (dragMove)="dragging = true"
    (dragEnd)="dragging = false"
    [ngClass]="{'pip': gameIframe.isPip, 'full-screen': fullScreen.isFullScreen, 'jackpot': jackpot.isJackpotEnable && gameIframe.game?.bonanzaJackpot}"
    [style.height.px]="gameIframe.iframeHeight"
    class="game-screen"
    >
    @if (jackpot.isJackpotEnable && gameIframe.game?.bonanzaJackpot) {
      <div class="jackpot__levels">
        <div class="container">
          <app-jackpot-levels [place]="levelPlaces.GAME"></app-jackpot-levels>
        </div>
      </div>
    }
    <div class="game">
      <iframe class="game__iframe" [src]="gameIframe.source"></iframe>
      @if (gameIframe.isPip) {
        <div class="game__actions">
          @if (gameIframe.game) {
            <p class="game__actions_name">{{ gameIframe.game?.name }}</p>
          }
          <div class="game__actions_row">
            <i class="nav__item icon-picture-in-picture" (click)="gameIframe.togglePipGame(false)"></i>
            <i class="nav__item icon-close" (click)="closeIframe()"></i>
          </div>
        </div>
      }
    </div>
    @if (dragging) {
      <div class="game-screen__drag"></div>
    }
  </div>
}



