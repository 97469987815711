<div class="wrapper">
  <div class="preloader">
    <div class="preloader__ring">
      <div class="preloader__sector">2</div>
      <div class="preloader__sector">1</div>
      <div class="preloader__sector">b</div>
      <div class="preloader__sector">i</div>
      <div class="preloader__sector">t</div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
    </div>
    <div class="preloader__ring">
      <div class="preloader__sector">2</div>
      <div class="preloader__sector">1</div>
      <div class="preloader__sector">b</div>
      <div class="preloader__sector">i</div>
      <div class="preloader__sector">t</div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
      <div class="preloader__sector"></div>
    </div>
  </div>
</div>
