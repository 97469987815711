<div [class]="'card bonus --' + bonus?.type">

  <div class="bonus__data">
    @switch (bonus?.type) {
      @case (bonuses.Type.MONEY) {
        <ng-container *ngTemplateOutlet="moneyData"></ng-container>
      }
      @case (bonuses.Type.FREE_SPINS) {
        <ng-container *ngTemplateOutlet="freespinData"></ng-container>
      }
      @case (bonuses.Type.LOOTBOX) {
        <ng-container *ngTemplateOutlet="lootboxData"></ng-container>
      }
      @case (bonuses.Type.DEPOSIT) {
        <ng-container *ngTemplateOutlet="depositData"></ng-container>
      }
    }
  </div>

  <div class="bonus__info">
    <div class="bonus__actions">
      @switch (bonus?.type) {
        @case (bonuses.Type.LOOTBOX) {
          <ng-container *ngTemplateOutlet="lootboxBonusAction"></ng-container>
        }
        @case (bonuses.Type.DEPOSIT) {
          <ng-container *ngTemplateOutlet="depositBonusAction"></ng-container>
        }
        @default {
          <ng-container *ngTemplateOutlet="defaultBonusAction"></ng-container>
        }
      }
    </div>

    @if (bonus?.stage) {
      <div class="bonus__statuses">
        <div class="bonus__statuses--stage" [ngClass]="bonus.stage">
          {{bonus?.stage_label | translate}}
        </div>
        @if (bonus?.valid_until) {
          <div class="bonus__line">
            <span class="lable">{{'t.finished-in' | translate }}:</span> {{ bonus?.valid_until | date:'MM-dd-y HH:mm' }}
          </div>
        }
      </div>
    }
  </div>

  <div class="bonus__img">
    @switch (bonus?.type) {
      @case (bonuses.Type.FREE_SPINS) {
        <ng-container *ngTemplateOutlet="freespinBonusImg"></ng-container>
      }
      @default {
        <ng-container *ngTemplateOutlet="defaultBonusImg"></ng-container>
      }
    }
  </div>
</div>


<!-- BONUS DATA -->
<ng-template #moneyData>
  <div class="bonus__top">
    <div class="bonus__top--type">{{bonusTypeTranslates[bonus?.type] | translate}}</div>
    @if (!bp.isMobile) {
      <div class="bonus__top--title"><span class="text-gradient" fittext [maxFontSize]="30" [minFontSize]="21">{{bonus?.title}}</span></div>
    }
    @if (bp.isMobile) {
      <div class="bonus__top--title"><span class="text-gradient" fittext [maxFontSize]="18" [minFontSize]="13">{{bonus?.title}}</span></div>
    }
  </div>
  @if (bonus?.wager || bonus?.wager === 0) {
    <div class="bonus__line">
      <span class="lable">{{'t.wager' | translate }}:</span> {{ bonus?.wager | number:'0.2-2' }}%
      @if (bonus?.wager || bonus?.wager === 0) {
        <div class="bonus__wager">
          <div class="bonus__wager--bar" [style.width]=" bonus?.wager + '%'"></div>
        </div>
      }
    </div>
  }
  @if (bonus?.amount) {
    <div class="bonus__line">
      <span class="lable">{{'labl.amount' | translate }}:</span> {{ bonus?.amount | currencyLocale:bonus?.currency_symbol}}
    </div>
  }
  @if (bonus?.valid_until) {
    <div class="bonus__line --mobile">
      <span class="lable">{{'t.finished-in' | translate }}:</span> {{ bonus?.valid_until | date:'MM-dd-y HH:mm' }}
    </div>
  }
  <div
    class="bonus__actions"
    [ngClass]="{'--stage-only': (!bonus.cancelable && !bonus.activatable)}">
    <ng-container *ngTemplateOutlet="defaultBonusAction"></ng-container>
  </div>
</ng-template>

<ng-template #freespinData>
  <div class="bonus__top">
    <div class="bonus__top--type">{{bonusTypeTranslates[bonus?.type] | translate}}</div>
    @if (!bp.isMobile) {
      <div class="bonus__top--title"><span class="text-gradient" fittext [maxFontSize]="30" [minFontSize]="21">{{bonus?.title}}</span></div>
    }
    @if (bp.isMobile) {
      <div class="bonus__top--title"><span class="text-gradient" fittext [maxFontSize]="18" [minFontSize]="13">{{bonus?.title}}</span></div>
    }
  </div>
  <div class="bonus__line">
    <span class="lable">{{'t.free-spins' | translate }}: </span> {{ bonus?.freespins_performed || 0 }}/{{ bonus?.freespins_total }}
  </div>
  @if (bonus?.games?.length) {
    <div class="bonus__line games">
      <span class="lable">{{'t.game' | translate }}: </span>
      @for (game of bonus.games; track game; let last = $last) {
        @if (game?.name && bonus?.games?.length > 1) {
          <a class="link" [routerLink]="['/games', 'free-spins']">{{ game?.name }}{{last ? '' : ', '}}</a>
        }
        @if (game?.name && bonus?.games?.length === 1) {
          <a class="link" [routerLink]="['/play', game?.slug]">{{ game?.name }}{{last ? '' : ', '}}</a>
        }
      }
    </div>
  }
  @if (bonus?.valid_until) {
    <div class="bonus__line --mobile">
      <span class="lable">{{'t.finished-in' | translate }}:</span> {{ bonus?.valid_until | date:'MM-dd-y HH:mm' }}
    </div>
  }
  @if (bonus.stage !== bonuses.Stage.ISSUED) {
    <div class="bonus__statuses--stage --mobile fs {{bonus.stage}}">
      {{bonus?.stage_label | translate}}
    </div>
  }
  <div
    class="bonus__actions"
    [ngClass]="{'--stage-only': (!bonus.cancelable && !bonus.activatable)}">
    <ng-container *ngTemplateOutlet="defaultBonusAction"></ng-container>
  </div>
</ng-template>

<ng-template #lootboxData>
  <div class="bonus__top">
    <div class="bonus__top--type">{{bonusTypeTranslates[bonus?.type] | translate}}</div>
    @if (!bp.isMobile) {
      <div class="bonus__top--title"><span class="text-gradient" fittext [maxFontSize]="30" [minFontSize]="21">{{bonus?.title | translate}}</span></div>
    }
    @if (bp.isMobile) {
      <div class="bonus__top--title"><span class="text-gradient" fittext [maxFontSize]="18" [minFontSize]="13">{{bonus?.title | translate}}</span></div>
    }
  </div>
  @if (bonus?.valid_until) {
    <div class="bonus__line --mobile">
      <span class="lable">{{'t.finished-in' | translate }}:</span> {{ bonus?.valid_until | date:'MM-dd-y HH:mm' }}
    </div>
  }
  <div
    class="bonus__actions"
    [ngClass]="{'--stage-only': bonus.stage !== bonuses.Stage.ISSUED}">
    <ng-container *ngTemplateOutlet="lootboxBonusAction"></ng-container>
  </div>
</ng-template>

<ng-template #depositData>
  <div class="bonus__top">
    <div class="bonus__top--type">{{bonus?.Title}}</div>
    @if (!bp.isMobile) {
      <div class="bonus__top--title">
        <span class="text-gradient" fittext [maxFontSize]="30" [minFontSize]="21">
          <span [innerHTML]="bonus?.Description | safe:'html'"></span>
        </span>
      </div>
    }
    @if (bp.isMobile) {
      <div class="bonus__top--title">
        <span class="text-gradient" fittext [maxFontSize]="18" [minFontSize]="13">
          <span [innerHTML]="bonus?.Description | safe:'html'"></span>
        </span>
      </div>
    }
  </div>
  <div class="bonus__line">
    <span class="lable">{{bonus?.ThirdDescription}}</span>
  </div>
  <div class="bonus__actions">
    <ng-container *ngTemplateOutlet="depositBonusAction"></ng-container>
  </div>
</ng-template>
<!-- BONUS DATA -->


<!-- BONUS ACTION -->
<ng-template #lootboxBonusAction>
  @if (!bonus.pending_activation && !bonus.loading) {
    @if (bonus.cancelable && bonus.stage === bonuses.Stage.ISSUED && bonus.activatable) {
      <button class="btn small" (click)="cancelDefault(bonus)">{{'btn.cancel' | translate}}</button>
    }
    @if (bonus.stage === bonuses.Stage.ISSUED && bonus.activatable) {
      <button class="btn small" (click)="activateLootbox(bonus)">{{'btn.open' | translate}}</button>
    }
  }
  @if (bonus.stage !== bonuses.Stage.ISSUED) {
    <div class="bonus__statuses--stage --mobile {{bonus.stage}}">
      {{bonus?.stage_label | translate}}
    </div>
  }
</ng-template>

<ng-template #depositBonusAction>
  <button class="btn-attention" (click)="openBonusDetails(bonus)">{{'btn.info' | translate}}</button>
  <button class="btn small" [routerLink]="['/', 'deposit']">{{'btn.use-bonus' | translate}}</button>
</ng-template>

<ng-template #defaultBonusAction>
  @if (bonus.pending_activation) {
    <small class="bonus__statuses--stage played pending-activation">{{'t.bonus-will-credited' | translate}}</small>
  }
  @if (!bonus.pending_activation && !bonus.loading) {
    @if (bonus.cancelable) {
      <button class="btn small" (click)="cancelDefault(bonus)">{{'btn.cancel' | translate}}</button>
    }
    @if (bonus.stage === bonuses.Stage.ISSUED && bonus.activatable) {
      <button class="btn small" (click)="activateDefault(bonus)">
        {{'btn.use-bonus' | translate}}
      </button>
    }
    @if (bonus?.stage === Stage.ACTIVATED && bonus?.type === Type.FREE_SPINS) {
      <button (click)="gamesLauncher.openGameByExternalId(bonus?.games_info[0])" class="btn small">
        {{'btn.play' | translate}}
      </button>
    }
  }
  @if (bonus.stage !== bonuses.Stage.ISSUED && bonus?.type !== Type.FREE_SPINS) {
    <div class="bonus__statuses--stage --mobile {{bonus.stage}}">
      {{bonus?.stage_label | translate}}
    </div>
  }
</ng-template>
<!-- BONUS ACTION -->


<!-- BONUS IMG -->
<ng-template #freespinBonusImg>
  @if (customFlow?.customFlowData$ | async; as customFlowData) {
    @if (customFlowData?.game$ | async; as game) {
      @if (bonus?.stage !== Stage.ACTIVATED) {
        <img [attr.data-src]="game?.imgSrc || bonus?.games[0]?.imgSrc" [alt]="seo.metaTitle">
      }
      @if (bonus?.stage === Stage.ACTIVATED && bonus?.type === Type.FREE_SPINS) {
        <img [attr.data-src]="game?.imgSrc || bonus?.games[0]?.imgSrc" [alt]="seo.metaTitle" (click)="gamesLauncher.openGameByExternalId(bonus?.games_info[0])">
      }
    }
  } @else {
    @if (bonus?.games?.length) {
      @if (bonus?.stage !== Stage.ACTIVATED) {
        <img [attr.data-src]="bonus?.games[0]?.imgSrc" [alt]="bonus?.games[0]?.name">
      }
      @if (bonus?.stage === Stage.ACTIVATED && bonus?.type === Type.FREE_SPINS) {
        <img [attr.data-src]="bonus?.games[0]?.imgSrc" [alt]="seo.metaTitle"  (click)="gamesLauncher.openGameByExternalId(bonus?.games_info[0])">
      }
    }
  }
</ng-template>

<ng-template #defaultBonusImg>
  <img class="--default" [attr.data-src]="'/assets/img/bonus/' + bonus?.type + '.png'" [alt]="seo.metaTitle">
</ng-template>
<!-- BONUS IMG -->
