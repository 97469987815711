import {Directive, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, Output} from '@angular/core';
import {tap} from 'rxjs/operators';
import {PlatformService} from '../../services/platform.service';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {GameIframeService} from '../../services/games/game-iframe.service';

export function isLeftButton(event: MouseEvent | TouchEvent): boolean {
  if (event.type === 'touchstart') {
    return true;
  }
  return (event.type === 'mousedown' && (event as MouseEvent).button === 0);
}

export function getEvent(event: MouseEvent | TouchEvent): MouseEvent | Touch {
  if (event.type === 'touchend' || event.type === 'touchcancel') {
    return (event as TouchEvent).changedTouches[0];
  }
  return event.type.startsWith('touch') ? (event as TouchEvent).targetTouches[0] : event as MouseEvent;
}

@UntilDestroy({ checkProperties: true })
@Directive({
    selector: '[dragg]',
    standalone: true
})
export class DraggableDirective implements OnDestroy{

  @Input() dragEventTarget: MouseEvent | TouchEvent;
  @Input() dragX = true;
  @Input() dragY = true;
  @Input() inViewport: boolean;
  @Input() dragOffset = 0;

  @Output() dragStart: EventEmitter<any> = new EventEmitter();
  @Output() dragMove: EventEmitter<any> = new EventEmitter();
  @Output() dragEnd: EventEmitter<any> = new EventEmitter();

  public isDragging: boolean;
  public lastPageX: number;
  public lastPageY: number;
  private _globalListeners = new Map<string, {
    handler: (event: Event) => void,
    options?: AddEventListenerOptions | boolean
  }>();
  private _elementWidth: number;
  private _elementHeight: number;
  private _vw: number;
  private _vh: number;

  @HostListener('mousedown', ['$event'])
  @HostListener('touchstart', ['$event'])
  onMousedown(event: MouseEvent | TouchEvent) {
    if (this._platform.isBrowser) {
      this.dragEventTarget = event;
      this._onMousedown(this.dragEventTarget);
    }
  }

  constructor(
    private _el: ElementRef<HTMLElement>,
    private _ngZone: NgZone,
    private _platform: PlatformService,
    private _gameIframe: GameIframeService
  ) {
    this._gameIframe.expandGame$.pipe(
      tap(() => {
        this._el.nativeElement.style.left = '';
        this._el.nativeElement.style.top = '';
      })
    ).subscribe();
  }

  ngOnDestroy(): void {
    this._removeEventListener();
  }

  private _onMousedown(event: MouseEvent | TouchEvent): void {
    if (!isLeftButton(event)) {
      return;
    }
    if (this.dragX || this.dragY) {
      const evt = getEvent(event);
      this._initDrag(evt.pageX, evt.pageY);
      this._addEventListeners(event);
      this.dragStart.emit(event);
    }
  }

  private _onMousemove(event: MouseEvent | TouchEvent): void {
    const evt = getEvent(event);
    this._onDrag(evt.pageX, evt.pageY);
    this.dragMove.emit(event);
  }

  private _onMouseup(event: MouseEvent | TouchEvent): void {
    this._endDrag();
    this._removeEventListener();
    this.dragEnd.emit(event);
  }

  private _addEventListeners(event: MouseEvent | TouchEvent): void {
    const isTouchEvent = event.type.startsWith('touch');
    const moveEvent = isTouchEvent ? 'touchmove' : 'mousemove';
    const upEvent = isTouchEvent ? 'touchend' : 'mouseup';

    this._globalListeners
      .set(moveEvent, {
        handler: this._onMousemove.bind(this),
        options: false
      })
      .set(upEvent, {
        handler: this._onMouseup.bind(this),
        options: false
      });

    this._ngZone.runOutsideAngular(() => {
      this._globalListeners.forEach((config, name) => {
        window.document.addEventListener(name, config.handler, config.options);
      });
    });
  }

  private _removeEventListener(): void {
    this._globalListeners.forEach((config, name) => {
      window.document.removeEventListener(name, config.handler, config.options);
    });
  }

  private _initDrag(pageX: number, pageY: number): void {
    this.isDragging = true;
    this.lastPageX = pageX;
    this.lastPageY = pageY;
    this._el.nativeElement.classList.add('dragging');
    this._el.nativeElement.style.cursor = 'grabbing';

    this._elementWidth = this._el.nativeElement.offsetWidth;
    this._elementHeight = this._el.nativeElement.offsetHeight;
    this._vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
    this._vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
  }

  private _onDrag(pageX: number, pageY: number): void {
    if (this.isDragging) {
      this._ngZone.runOutsideAngular(() => {
        const deltaX = pageX - this.lastPageX;
        const deltaY = pageY - this.lastPageY;
        const coords = this._el.nativeElement.getBoundingClientRect();
        let leftPos = coords.left + deltaX;
        let topPos = coords.top + deltaY;

        const overWidth = !this.inViewport || leftPos >= 0 && (leftPos + this._elementWidth) <= this._vw;
        const overHeight = !this.inViewport || topPos >= 0 && (topPos + this._elementHeight) <= this._vh;
        if (overWidth) {
          this.lastPageX = pageX;
        }
        if (overHeight) {
          this.lastPageY = pageY;
        }

        if (this.inViewport) {
          if (leftPos < 0) {
            leftPos = 0;
          }
          if ((leftPos + this._elementWidth) > this._vw) {
            leftPos = this._vw - this._elementWidth;
          }
          if (topPos < 0) {
            topPos = 0;
          }
          if ((topPos + this._elementHeight) > this._vh) {
            topPos = this._vh - this._elementHeight;
          }
        }
        this._el.nativeElement.style.left = leftPos + 'px';
        this._el.nativeElement.style.top = topPos + 'px';
        this._addOffsetClass(topPos, leftPos, this._vw - (this._elementWidth + leftPos), this._vh - (this._elementHeight + topPos));
      });
    }
  }

  private _endDrag(): void {
    this._el.nativeElement.style.cursor = '';
    this.isDragging = false;
    this._el.nativeElement.classList.remove('dragging');
  }

  private _addOffsetClass(topPos, leftPos, rightPos, bottomPos) {
    topPos < this.dragOffset ? this._el.nativeElement.classList.add('offset--top') : this._el.nativeElement.classList.remove('offset--top');
    leftPos < this.dragOffset ? this._el.nativeElement.classList.add('offset--left') : this._el.nativeElement.classList.remove('offset--left');
    rightPos < this.dragOffset ? this._el.nativeElement.classList.add('offset--right') : this._el.nativeElement.classList.remove('offset--right');
    bottomPos < this.dragOffset ? this._el.nativeElement.classList.add('offset--bottom') : this._el.nativeElement.classList.remove('offset--bottom');
  }
}
