import { Component, Input } from '@angular/core';
import { Observable, of } from 'rxjs';
import { catchError, delay, filter, first, switchMap, tap } from 'rxjs/operators';
import { ToastMessageService } from '../../modules/toast-message/toast-message.service';
import { ModalService } from '../../../modal/modal.service';
import { UserBonusesService } from '../../../services/user/user-bonuses.service';
import { LootboxService } from '../../../services/lootbox/lootbox.service';
import { GamesLauncherService } from '../../../services/games/games-launcher.service';
import { BonusStage, BonusType } from '../../../services/user/data/user-bonuses.data';
import { BreakpointsDetectorService } from '../../../services/breakpoints-detector.service';
import { CustomUserFlowService } from '../../../services/custom-user-flow.service';
import { SeoService } from '../../../services/seo.service';
import { TranslatePipe } from '../../../services/translation/translate.pipe';
import { CurrencyLocalePipe } from '../../pipes/currency-locale.pipe';
import { SafePipe } from '../../pipes/safe.pipe';
import { BtnComponent } from '../btn/btn.component';
import { RouterLinkDirective } from '../../directives/router-link.directive';
import { RouterLink } from '@angular/router';
import { FittextDirective } from '../../directives/fit-text.directive';
import { TextGradientComponent } from '../text-gradient/text-gradient.component';
import { NgTemplateOutlet, NgClass, AsyncPipe, DecimalPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-bonus-preview',
    templateUrl: './bonus-preview.component.html',
    styleUrls: ['./bonus-preview.component.scss'],
    standalone: true,
  imports: [
    NgTemplateOutlet,
    NgClass,
    TextGradientComponent,
    FittextDirective,
    RouterLink,
    RouterLinkDirective,
    BtnComponent,
    AsyncPipe,
    DecimalPipe,
    DatePipe,
    SafePipe,
    CurrencyLocalePipe,
    TranslatePipe,
  ],
})
export class BonusPreviewComponent {

  public bonusTypeTranslates = {
    money: 't.cash-bonus',
    free_spins: 't.free-spins',
    lootboxes: 't.lootboxes',
    deposit: 't.deposit',
  };

  /**
   * Bonus object to display
   */
  @Input('bonus') bonus;

  constructor(
    private _toastMessage: ToastMessageService,
    private _modals: ModalService,
    private _lootbox: LootboxService,
    public bonuses: UserBonusesService,
    public seo: SeoService,
    public bp: BreakpointsDetectorService,
    public gamesLauncher: GamesLauncherService,
    public customFlow: CustomUserFlowService,
  ) {
  }

  get Stage() {
    return BonusStage;
  }

  get Type() {
    return BonusType;
  }

  /**
   * Activate money/freespin bonus
   * @param bonus
   */
  public activateDefault(bonus: any) {
    bonus.loading = true;
    (this._isFSBonus(bonus) ? this.bonuses.activateFreeSpins(bonus.id) : this.bonuses.activateBonus(bonus.id)).pipe(
      delay(500),
      tap(() => {
        bonus.pending_activation = true;
        this._toastMessage.success('t.bonus-activated-msg');
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      }),
    ).subscribe(() => {
      bonus.loading = false;
      this.bonuses.updateBonusList();
    });
  }

  /**
   * Cancel money/freespin bonus
   * @param bonus
   */
  public async cancelDefault(bonus: any) {
    const component = await import('../../../../core/modal/components/lazy/bonus-cancel-confirmation-modal/bonus-cancel-confirmation-modal.component');
    const modal = await this._modals.openLazy(component?.BonusCancelConfirmationModalComponent);
    modal.onResult().pipe(
      first(),
      filter(confirm => !!confirm),
      switchMap(() => {
        bonus.loading = true;
        return this._cancelBonusByType(bonus);
      }),
      tap(() => {
        this._toastMessage.success('t.bonus-canceled');
      }),
      catchError(error => {
        this._toastMessage.error('t.error');
        return of(error);
      }),
    ).subscribe(() => {
      bonus.loading = false;
      this.bonuses.updateBonusList();
    });
  }

  public async openBonusDetails(bonus) {
    const component = await import('./../../../modal/components/lazy/bonus-details-modal/bonus-details-modal.component');
    await this._modals.openLazy(component?.BonusDetailsModalComponent,
      {
        data: bonus,
      },
    );
  }

  public async activateLootbox(lootbox) {
    const component = await import('./../../../modal/components/lazy/lootbox/lootbox.component');
    const modal = await this._modals.openLazy(
      component?.LootboxComponent,
      { data: { lootbox } },
    );

    modal.onResult().pipe(
      tap(() => {
        if (this._lootbox.currentLootboxPrize) {
          this._lootbox.currentLootboxPrize = null;
          this.bonuses.updateBonusList();
        }
      })).subscribe();
  }

  /**
   * Check is bonus FS
   * @private
   */
  private _isFSBonus(bonus) {
    return bonus.activation_path && bonus.activation_path.includes('freespins');
  }

  /**
   * Check is lootbox bonus
   * @param bonus
   * @private
   */
  private _isLootboxBonus(bonus) {
    return bonus.type === 'lootboxes';
  }

  /**
   * Cancel bonus by type of bonus
   * @param bonus
   * @private
   */
  private _cancelBonusByType(bonus): Observable<any> {
    switch (true) {
      case this._isFSBonus(bonus):
        return this.bonuses.cancelFreeSpins(bonus.id);
      case this._isLootboxBonus(bonus):
        return this._lootbox.cancelLootbox(bonus.id);
      default:
        return this.bonuses.cancelBonus(bonus.id);
    }
  }

}
