
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PipesModule } from '../pipes/pipes.module';
import { RouterModule } from '@angular/router';






import { TopAuthBlockComponent } from './top-auth-block/top-auth-block.component';
import { GameIframeComponent } from './game-iframe/game-iframe.component';

import { HeaderBalanceComponent } from './header-balance/header-balance.component';

import { BonusPreviewComponent } from './bonus-preview/bonus-preview.component';
import { TopBackComponent } from './top-back/top-back.component';

import { BurgerMenuComponent } from './burger-menu/burger-menu.component';
import { LocalesSelectorComponent } from './locales-selector/locales-selector.component';
import { RadialProgressComponent } from './radial-progress/radial-progress.component';
import { StickyMenuComponent } from './sticky-menu/sticky-menu.component';
import { ThemeSelectorComponent } from './theme-selector/theme-selector.component';
import { ErrorsPageComponent } from './errors-page/errors-page.component';
import { PaginationComponent } from './pagination/pagination.component';

import { RegisterFormComponent } from './register-form/register-form.component';
import { LoyaltyProgressBarComponent } from './loyalty-progress-bar/loyalty-progress-bar.component';
import { VerifyComponent } from './verify/verify.component';
import { LoginFormComponent } from './login-form/login-form.component';
import { ScrollBoosterComponent } from './scroll-booster/scroll-booster.component';
import { NotificationCenterModule } from '../modules/notification-center/notification-center.module';
import { PhoneFormComponent } from './phone-form/phone-form.component';

import {
  CurrencyPaymentModeSelectorComponent,
} from './currency-payment-mode-selector/currency-payment-mode-selector.component';
import { BottomTermsComponent } from './bottom-terms/bottom-terms.component';
import { PaymentImagesComponent } from './payment-images/payment-images.component';
import { GamePreviewComponent } from './game-preview/game-preview.component';
import { GameListComponent } from './game-list/game-list.component';
import { TournamentPreviewTimerComponent } from './tournament-preview-timer/tournament-preview-timer.component';
import { TournamentPrizesComponent } from './tournament-prizes/tournament-prizes.component';
import { TournamentPreviewComponent } from './tournament-preview/tournament-preview.component';
import { TournamentLeadersComponent } from './tournament-leaders/tournament-leaders.component';


import { PaymentModeSwitcherComponent } from './payment-mode-switcher/payment-mode-switcher.component';

import { NgxMaskDirective, provideNgxMask } from 'ngx-mask';
import { SocialAuthComponent } from './social-auth/social-auth.component';
import { InstallAppBannerComponent } from './install-app-banner/install-app-banner.component';
import { JsonLdComponent } from './json-ld/json-ld.component';
import { UnlockLoyaltyComponent } from './unlock-loyalty/unlock-loyalty.component';
import { JackpotLevelsComponent } from './jackpot-levels/jackpot-levels.component';


import { LootboxPreviewComponent } from './lootbox-preview/lootbox-preview.component';
import { AuthFieldsMissedComponent } from './auth-fields-missed/auth-fields-missed.component';
import { EmptyPaymentsComponent } from './empty-payments/empty-payments.component';
import { CounterComponent } from './counter/counter.component';
import { EarnPointsComponent } from './earn-points/earn-points.component';
import {AccordionButtonComponent} from "./accordion-button/accordion-button.component";
import { CountdownCircleComponent } from './countdown-circle/countdown-circle.component';

@NgModule({
    imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    RouterModule,
    NotificationCenterModule,
    NgxMaskDirective,
    TopAuthBlockComponent,
    GameIframeComponent,
    HeaderBalanceComponent,
    BonusPreviewComponent,
    LocalesSelectorComponent,
    BurgerMenuComponent,
    StickyMenuComponent,
    ThemeSelectorComponent,
    RadialProgressComponent,
    TopBackComponent,
    ErrorsPageComponent,
    PaginationComponent,
    RegisterFormComponent,
    LoyaltyProgressBarComponent,
    VerifyComponent,
    LoginFormComponent,
    ScrollBoosterComponent,
    PhoneFormComponent,
    BottomTermsComponent,
    CurrencyPaymentModeSelectorComponent,
    PaymentImagesComponent,
    GamePreviewComponent,
    GameListComponent,
    TournamentLeadersComponent,
    TournamentPreviewTimerComponent,
    TournamentPreviewComponent,
    TournamentPrizesComponent,
    PaymentModeSwitcherComponent,
    SocialAuthComponent,
    InstallAppBannerComponent,
    JsonLdComponent,
    UnlockLoyaltyComponent,
    JackpotLevelsComponent,
    LootboxPreviewComponent,
    EmptyPaymentsComponent,
    AuthFieldsMissedComponent,
    EarnPointsComponent,
    CounterComponent,
    AccordionButtonComponent,
    CountdownCircleComponent,
],
    exports: [
    TopAuthBlockComponent,
    GameIframeComponent,
    HeaderBalanceComponent,
    LocalesSelectorComponent,
    BurgerMenuComponent,
    BonusPreviewComponent,
    StickyMenuComponent,
    ThemeSelectorComponent,
    RadialProgressComponent,
    TopBackComponent,
    ErrorsPageComponent,
    PaginationComponent,
    RegisterFormComponent,
    LoyaltyProgressBarComponent,
    VerifyComponent,
    LoginFormComponent,
    ScrollBoosterComponent,
    PhoneFormComponent,
    CurrencyPaymentModeSelectorComponent,
    BottomTermsComponent,
    PaymentImagesComponent,
    GamePreviewComponent,
    GameListComponent,
    TournamentLeadersComponent,
    TournamentPreviewTimerComponent,
    TournamentPreviewComponent,
    TournamentPrizesComponent,
    PaymentModeSwitcherComponent,
    SocialAuthComponent,
    InstallAppBannerComponent,
    JsonLdComponent,
    UnlockLoyaltyComponent,
    JackpotLevelsComponent,
    LootboxPreviewComponent,
    EmptyPaymentsComponent,
    AuthFieldsMissedComponent,
    CounterComponent,
    EarnPointsComponent,
    AccordionButtonComponent,
    CountdownCircleComponent
],
    providers: [
        provideNgxMask(),
    ],
})
export class ComponentsModule {
}
