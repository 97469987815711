import { Component, Input, OnInit } from '@angular/core';
import { UntilDestroy } from '@ngneat/until-destroy';
import { BaseTimerComponent } from 'src/app/core/helpers/base-timer.component';
import { TimelineStatus } from 'src/app/core/services/time.service';
import { TranslatePipe } from '../../../services/translation/translate.pipe';
import { NgTemplateOutlet } from '@angular/common';


@UntilDestroy()
@Component({
    selector: 'app-tournament-preview-timer',
    templateUrl: './tournament-preview-timer.component.html',
    styleUrls: ['./tournament-preview-timer.component.scss'],
    standalone: true,
    imports: [NgTemplateOutlet, TranslatePipe]
})
export class TournamentPreviewTimerComponent extends BaseTimerComponent implements OnInit {

  public readonly timelineStatus = TimelineStatus;

  @Input('status') status: TimelineStatus;

  constructor() {
    super();
  }

  ngOnInit() {
    this.resolveTimer();
  }

}
