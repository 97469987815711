
@if (tournament?.GroupList?.CustomPrizeList) {
  <ng-container *ngTemplateOutlet="customPrizes"></ng-container>
} @else {
  @if (prizesData$ | async; as data) {
    <div class="prizes">
      @if (!hideTitle) {
        <div class="prizes__title">{{'t.prizes' | translate}}:</div>
      }
      @if (data?.previewPrizes) {
        @for (prize of data?.previewPrizes; track prize) {
          <div [ngClass]="{'prizes__place': !alternativeStyle, 'prizes__place_alternative': alternativeStyle}" class="prizes__place">
            <div [ngClass]="{'prizes__place--position': !alternativeStyle, 'prizes__place_alternative--position-alternative': alternativeStyle}" class="text-gradient">
              {{prize?.place}} {{prize?.place_sufix}}
            </div>
            @if (prize?.money_award_cents) {
              <div class="prizes__place--value">
                {{ prize?.currency_symbol }} {{ prize?.money_award }}
              </div>
            }
            @if (prize.freespins_count) {
              <div class="prizes__place--value">
                {{ prize?.freespins_count }} {{ 'labl.free-spin' | translate }}
              </div>
            }
          </div>
        }
        @if (data?.mappedPrizes.length > 5) {
          <div class="prizes__place_alternative showPrizes" (click)="showPrizes()">
            <div class="showPrizes__title">
              {{'t.show-all-prizes' | translate}}
            </div>
            <div class="prizes__place--value">
              <div class="link"><i class="icon-arrow"></i></div>
            </div>
          </div>
        }
      }
    </div>
  }
}



<ng-template #customPrizes>
  <div class="prizes">
    @if (!hideTitle) {
      <div class="prizes__title">{{'t.prizes' | translate}}:</div>
    }
    @for (prize of tournament?.GroupList?.CustomPrizeList | keyvalue: originalOrder | slice: 0: 5; track prize) {
      <div [ngClass]="{'prizes__place': !alternativeStyle, 'prizes__place_alternative': alternativeStyle}" class="prizes__place">
        <div [ngClass]="{'prizes__place--position': !alternativeStyle, 'prizes__place_alternative--position-alternative': alternativeStyle}" class="text-gradient">
          {{prize?.key}}
        </div>
        <div class="prizes__place--value">
          {{prize?.value}}
        </div>
      </div>
    }
    @if ((tournament?.GroupList?.CustomPrizeList | keysCount) > 5) {
      <div class="prizes__place_alternative showPrizes" (click)="showPrizes()">
        <div class="showPrizes__title">
          {{'t.show-all-prizes' | translate}}
        </div>
        <div class="prizes__place--value">
          <div class="link"><i class="icon-arrow"></i></div>
        </div>
      </div>
    }
  </div>
</ng-template>

